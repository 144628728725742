export function convert(d, locale) {
  if (!d) return;

  const date = new Date(d);

  if (locale) {
    const day = date.getDate();
    const hour = date.getHours();
    const year = date.getFullYear();
    const minutes = date.getMinutes();
    const month = date.toLocaleString(locale, { month: 'long' });

    return `${day} ${month} ${year} ${value(hour)}:${value(minutes)}`;
  }

  return date.toLocaleDateString().replace(/\//g, '-');
}

export function format(input) {
  if (!input.value) return (input.dataset.validatedValue = '');

  if (input.value.match(/^(\d|-)+$/) && input.value.length <= 10) {
    // Do not validate date if user clears value by backspace
    if (input.value.length < input.dataset.validatedValue.length) return;

    input.dataset.validatedValue = input.value;
  } else {
    input.value = input.dataset.validatedValue;
  }

  // Append dashes
  if (input.value.match(/^(\d\d|\d\d-\d\d)$/))
    input.dataset.validatedValue = input.value = `${input.value}-`;

  // Prepend 0 for one-figure segments
  if (input.value.match(/^\d-$/))
    input.dataset.validatedValue = input.value = `0${input.value}`;

  // Prepend 0 for one-figure segments
  if (input.value.match(/^\d\d-\d-$/)) {
    const [day, month] = input.value.split('-');

    input.dataset.validatedValue = input.value = `${day}-0${month}-`;
  }
}

export function buildDateString(date) {
  const startDay = date.getDate();
  const startYear = date.getFullYear();
  const startMonth = date.getMonth() + 1;

  return `${startYear}-${startMonth < 10 ? `0${startMonth}` : startMonth}-${
    startDay < 10 ? `0${startDay}` : startDay
  }`;
}

function value(value) {
  return value < 10 ? `0${value}` : value;
}

export function timeSince(date) {
  const now = new Date();

  const seconds =
    Math.floor((now - date) / 1000) + now.getTimezoneOffset() * 60 + 60 * 60;

  let interval = seconds / 31536000;

  if (interval > 1) return displayedValue(interval, 'jaar', 'jaren');

  interval = seconds / 2592000;

  if (interval > 1) return displayedValue(interval, 'maand', 'maanden');

  interval = seconds / 86400;

  if (interval > 1) return displayedValue(interval, 'dag', 'dagen');

  interval = seconds / 3600;

  if (interval > 1) return displayedValue(interval, 'uur', 'uur');

  interval = seconds / 60;

  if (interval > 1) return displayedValue(interval, 'minuut', 'minuten');

  return `nu`;

  function displayedValue(interval, singular, plural) {
    const value = Math.floor(interval);

    return `${value} ${value > 1 ? plural : singular} geleden`;
  }
}

export function getPregnancyPeriod() {
  const start = new Date();
  const end = new Date();

  end.setDate(end.getDate() + 40 * 7);

  return { start, end };
}

export function getPastPregnancyPeriod() {
  const start = new Date();
  const end = new Date();

  start.setDate(end.getDate() - 40 * 7);

  return { start, end };
}

export function mutationFormat(date) {
  const offset = date.getTimezoneOffset();

  const d = new Date(date.getTime() - offset * 60 * 1000);

  return d.toISOString().split('T')[0];
}
