import React from 'react';

import Eye from './eye.svg';
import File from './file.svg';
import Edit from './edit.svg';
import Mail from './mail.svg';
import User from './user.svg';
import Time from './time.svg';
import Star from './star.svg';
import News from './news.svg';
import Chat from './chat.svg';
import More from './more.svg';
import Child from './child.svg';
import Globe from './globe.svg';
import Cross from './cross.svg';
import Ruler from './ruler.svg';
import Heart from './heart.svg';
import Alarm from './alarm.svg';
import Phone from './phone.svg';
import Apple from './apple.svg';
import Folder from './folder.svg';
import Upload from './upload.svg';
import EyeOff from './eyeOff.svg';
import Filter from './filter.svg';
import Weight from './weight.svg';
import Search from './search.svg';
import Twitter from './twitter.svg';
import ArrowUp from './arrowUp.svg';
import Settings from './settings.svg';
import Download from './download.svg';
import Question from './question.svg';
import Whatsapp from './whatsapp.svg';
import HalfStar from './halfStar.svg';
import Location from './location.svg';
import CheckBox from './checkBox.svg';
import Calendar from './calendar.svg';
import Facebook from './facebook.svg';
import VideoCall from './videoCall.svg';
import FileShare from './fileShare.svg';
import ArrowDown from './arrowDown.svg';
import Subscribe from './subscribe.svg';
import Instagram from './instagram.svg';
import LeftArrow from './leftArrow.svg';
import GooglePlay from './googlePlay.svg';
import RightArrow from './rightArrow.svg';
import Exclamation from './exclamation.svg';
import Appointment from './appointment.svg';
import ShoppingCart from './shoppingCart.svg';
import CheckBoxCircle from './checkBoxCircle.svg';
import ProfileSettings from './profileSettings.svg';

export default {
  RightArrow,
  Eye: () => <Eye />,
  File: () => <File />,
  News: () => <News />,
  More: () => <More />,
  Mail: () => <Mail />,
  User: () => <User />,
  Chat: () => <Chat />,
  Time: () => <Time />,
  Star: () => <Star />,
  Edit: () => <Edit />,
  Child: () => <Child />,
  Apple: () => <Apple />,
  Alarm: () => <Alarm />,
  Phone: () => <Phone />,
  Globe: () => <Globe />,
  Cross: () => <Cross />,
  Ruler: () => <Ruler />,
  Heart: () => <Heart />,
  Folder: () => <Folder />,
  Upload: () => <Upload />,
  EyeOff: () => <EyeOff />,
  Filter: () => <Filter />,
  Weight: () => <Weight />,
  Search: () => <Search />,
  Twitter: () => <Twitter />,
  ArrowUp: () => <ArrowUp />,
  Settings: () => <Settings />,
  Download: () => <Download />,
  Question: () => <Question />,
  Whatsapp: () => <Whatsapp />,
  HalfStar: () => <HalfStar />,
  Location: () => <Location />,
  CheckBox: () => <CheckBox />,
  Calendar: () => <Calendar />,
  Facebook: () => <Facebook />,
  FileShare: () => <FileShare />,
  VideoCall: () => <VideoCall />,
  ArrowDown: () => <ArrowDown />,
  Subscribe: () => <Subscribe />,
  Instagram: () => <Instagram />,
  LeftArrow: () => <LeftArrow />,
  GooglePlay: () => <GooglePlay />,
  Appointment: () => <Appointment />,
  Exclamation: () => <Exclamation />,
  ShoppingCart: () => <ShoppingCart />,
  CheckBoxCircle: () => <CheckBoxCircle />,
  ProfileSettings: () => <ProfileSettings />
};
