import React from 'react';
import { CSSTransition } from 'react-transition-group';

import './Transition.scss';

export default function Transition({ children, on: condition, className }) {
  return (
    <CSSTransition
      in={!!condition}
      timeout={200}
      classNames={className || 'transition'}
    >
      {() => condition && children}
    </CSSTransition>
  );
}
